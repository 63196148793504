import React from "react"
import { graphql } from "gatsby"
import { Layout, FeaturedMedia, AdsenseBanner, SocialButtons, Comments, Seo } from "../components"
import HTMLReactParser from "html-react-parser"

const postUri = (uri) => {
  return `${process.env.LIVE_URL_SLASH}${uri}`
}

const toggleTOC = () => {
  let collapse = false;

  const toggle = document.querySelector(".lwptoc_toggle");

  const toc = document.querySelector(".lwptoc_items");
  toc.style.height = "auto";

  toggle.addEventListener("click", (e) => {
    e.preventDefault(); // prevent default behavior

    if (!collapse) {
      toc.style.height = "0px";
      toc.style.width = "0px";

      collapse = true;

    } else {

      toc.style.height = "auto";
      toc.style.width = "auto";

      collapse = false;
    }

    const timer = setTimeout(() => {
      toc.style.display = "none";
    }, 300);


    clearTimeout(timer);
  });

};

const Post = (props) => {
  const {
    data: { page },
    pageContext: { schema }
  } = props

  const { head } = schema
  const {
    title,
    uri,
    content,
    featuredImage,
    // categories,
    databaseId,
  } = page


  const manageAdsense = (content) => {
    const arrC = HTMLReactParser(content);
    const slots = process.env.ADSENSE_SLOT.replaceAll('[', '').replaceAll(']', '').split(",");

    const ads = (idx) => <AdsenseBanner
      className="adsbygoogle"
      style={{ display: 'block' }}
      slot={slots[idx]}
      currentPath={`${uri}-${slots[idx]}`}
      format="auto"
      responsive="true"
    />;

    arrC.unshift(ads(0));

    if (arrC.length / 2 >= 30) {
      arrC.splice(arrC.length / 2, 0, ads(1));
      arrC.push(ads(2));
    }

    return arrC;
  }

  React.useLayoutEffect(() => {
    toggleTOC();
  }, []);

  return (
    <Layout hasTopBtn>
      <Seo head={head} pageData={page} />
      <article className={`post-${databaseId} w-full`} id={`post-${databaseId}`}>
        <header>
          <div>
            {/* <CategoryBadge name={categories} /> */}
            <h1 dangerouslySetInnerHTML={{ __html: title }} />
          </div>
        </header>
        <FeaturedMedia image={featuredImage} />
        <div id="contenido-full">
          {manageAdsense(content)}
        </div>
      </article>
      <SocialButtons
        facebook={{ url: postUri(uri), title }}
        twitter={{ url: postUri(uri), title }}
        email={{ url: postUri(uri), title }}
        linkedin={{ url: postUri(uri), title }}
        telegram={{ url: postUri(uri), title }}
        whastapp={{ url: postUri(uri), title }}
      />

      <Comments pageID={databaseId} />
    </Layout>
  )
}

export const query = graphql`
query post($id: String!, $nextPage: String, $previousPage: String) {
  page: wpPost(id: { eq: $id }) {
    ...PostContent
    author {
      node {
        name
        uri
        description
        avatar {
          url
        }
      }
    }
  }
  nextPage: wpPost(id: { eq: $nextPage }) {
    title
    uri
  }
  previousPage: wpPost(id: { eq: $previousPage }) {
    title
    uri
  }
}
`

export default Post;